import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Menu from '../../components/menu';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export default function Keys() {
    const [gamesData, setGamesData] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState({
        id: '',
    });
    const [newKey, setNewKey] = useState({
        game: '',
        tier: '',
        expiry: 0,
        unit: 'day',
        quantity: 1,
        note: '',
    });
    const [keysQuery, setKeysQuery] = useState('');
    const [keysQueryUsed, setKeysQueryUsed] = useState([]);
    const [keysQueryUnused, setKeysQueryUnused] = useState([]);

    useEffect(() => {
        const fetchGamesList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/games/list`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const result = await response.json();
                if (result && result.data) {
                    setGamesData(result.data);
                }
            } catch (error) {
                console.error("Error fetching games:", error);
                toast.error("Error fetching games");
            }
        }

        if (gamesData.length <= 0)
            fetchGamesList();

        if (gamesData.length > 0 && !$.fn.DataTable.isDataTable('#keys_table')) {
            $('#keys_table').DataTable({
                serverSide: true,
                processing: true,
                ajax: `${process.env.REACT_APP_API_URL}/subscriptions/data_table`,
                columns: [
                    { data: '_id', visible: false },
                    { data: 'key' },
                    {
                        data: 'game',
                        render: (data, type, row) => {
                            const game = gamesData.find(game => game._id === data);
                            return game ? game.name : '';
                        },
                    },
                    { data: 'tier' },
                    {
                        data: 'createdAt',
                        render: (data, type, row) => {
                            return moment(data).format('DD/MM/YYYY HH:mm');
                        }
                    },
                    {
                        data: 'validFor',
                        render: (data) => {
                            const duration = moment.duration(data, 'seconds');

                            const months = duration.months();
                            let remainingDays = duration.days();
                            const weeks = Math.floor(remainingDays / 7);
                            remainingDays %= 7;

                            let output = "";

                            if (months > 0) {
                                output += `${months} Month${months > 1 ? 's' : ''} `;
                            }
                            if (weeks > 0) {
                                output += `${weeks} Week${weeks > 1 ? 's' : ''} `;
                            }
                            if (remainingDays > 0) {
                                output += `${remainingDays}D`;
                            }

                            return output.trim();
                        },
                    },
                    {
                        data: 'activatedBy',
                        render: (data, type, row) => {
                            return data ? data : 'N/A';
                        }
                    },
                    {
                        data: 'paused',
                        render: (data, type, row) => {
                            return data ? 'YES' : 'NO';
                        }
                    },
                    {
                        data: 'note',
                        render: (data, type, row) => {
                            return data;
                        }
                    },
                    {
                        data: null,
                        render: (data, type, row) => {
                            return `
                          <button class="btn btn-danger delete-btn" data-id="${row._id}" data-toggle="modal" data-target="#deleteModal"><i class="fas fa-trash"></i></button>
                        `;
                        },
                        orderable: false
                    },
                ],
                columnDefs: [
                    { width: "13%", targets: 1 },
                    { width: "13%", targets: 6 },
                    { width: "3%", targets: 7 },
                    { width: "15%", targets: 8 },
                    { width: "2%", targets: 9 }
                ]
            });
        }
        $('#keys_table tbody').on('click', '.delete-btn', (e) => {
            const id = $(e.currentTarget).data('id');
            setSelectedSubscription(prevSubscription => ({
                ...prevSubscription,
                id
            }));
        });
    }, [gamesData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewKey(prevState => ({ ...prevState, [name]: value }));
    };

    const handleQueryInputChange = (e) => {
        const { name, value } = e.target;
        setKeysQuery(value);
    };

    const handleAddKey = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/subscriptions/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newKey)
            });
            const result = await response.json();

            if (result.success) {
                $('#addKeyModal').modal('hide');
                $('#keys_table').DataTable().ajax.reload();

                // Displaying the generated keys using MySwal
                MySwal.fire({
                    title: <strong>Success!</strong>,
                    html: (
                        <div className="text-center">
                            <i>Here are your keys:</i>
                            <ul className="list-unstyled"> {/* Remove bullets using Bootstrap's class */}
                                {result.keys.map(key => (
                                    <li key={key}>{key}</li>
                                ))}
                            </ul>
                        </div>
                    ),
                    icon: 'success'
                });
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    };

    const handleQueryKeys = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/subscriptions/query_keys`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ keys: keysQuery })
            });
            const result = await response.json();
            if (result.success) {
                setKeysQueryUsed(result.usedKeys);
                setKeysQueryUnused(result.unusedKeys);
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error.message, "Error");
        }
    };

    const handleDeleteSubscription = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/subscriptions/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: selectedSubscription.id })
            });
            const result = await response.json();
            if (result.success) {
                // Refresh the DataTables and Close modal
                $('#keys_table').DataTable().ajax.reload();
                $('#deleteModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    };

    return (
        <div>
            <Header />
            <Menu />

            <div className="modal fade" id="addKeyModal" tabIndex="-1" role="dialog" aria-labelledby="addKeyModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {/* ... */}
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Game</label>
                                    <select
                                        name="game"
                                        className="form-control"
                                        value={newKey.game}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                            const game = gamesData.find(g => g._id === e.target.value);
                                            setSelectedGame(game);
                                        }}
                                    >

                                        <option value="">Select a game</option>
                                        {gamesData.length > 0 && gamesData.map((game, index) => (
                                            <option key={game._id} value={game._id}>{game.name} - {game.desc}</option>
                                        ))}
                                    </select>
                                </div>
                                {selectedGame && (
                                    <div className="form-group">
                                        <label>Tier</label>
                                        <select
                                            name="tier"
                                            className="form-control"
                                            value={newKey.tier}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select a Tier</option>
                                            {selectedGame.tiers && selectedGame.tiers.map((tier, index) => (
                                                <option key={tier.id} value={tier.id}>{tier.id}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {selectedGame && (
                                    <div className="form-group">
                                        <label>Expiry Unit</label>
                                        <select name="unit" className="form-control" onChange={handleInputChange} value={newKey.unit}>
                                            <option value="day">Day</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>
                                            <option value="lifetime">Lifetime</option>
                                        </select>
                                    </div>
                                )}
                                {selectedGame && (
                                    <div className="form-group">
                                        <label>Valid For</label>
                                        <input
                                            type="number"
                                            name="expiry"
                                            className="form-control"
                                            value={newKey.validFor}
                                            onChange={handleInputChange}
                                            placeholder="Enter validity duration"
                                        />
                                    </div>
                                )}
                                {selectedGame && (
                                    <div className="form-group">
                                        <label>Quantity</label>
                                        <input type="number" name="quantity" value={newKey.quantity} onChange={handleInputChange} className="form-control" />
                                    </div>
                                )}
                                <div className="form-group">
                                    <label>Note</label>
                                    <input type="text" name="note" value={newKey.note} onChange={handleInputChange} className="form-control" />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" disabled={!(newKey.game && newKey.tier && newKey.expiry > 0)} onClick={handleAddKey}>Add Key</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="queryKeysModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Query Keys</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Keys</label>
                                    <textarea name="keys_query" onChange={handleQueryInputChange} rows="3" placeholder="Enter the keys..." className="form-control"></textarea>
                                </div>
                                {keysQueryUsed.length > 0 && (
                                    <div className="form-group">
                                        <label>Used Keys</label>
                                        <textarea name="keys_query_used" rows="3" value={keysQueryUsed.join('\n')} className="form-control" readOnly></textarea>
                                    </div>
                                )}
                                {keysQueryUnused.length > 0 && (
                                    <div className="form-group">
                                        <label>Unused Keys</label>
                                        <textarea name="keys_query_unused" rows="3" value={keysQueryUnused.join('\n')} className="form-control" readOnly></textarea>
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleQueryKeys}>Query</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Modal */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this Key?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteSubscription}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h1 className="m-0 mr-3">Keys</h1>
                                <button className="btn btn-success" data-toggle="modal" data-target="#addKeyModal">
                                    <i className="fas fa-plus"></i> Create Key
                                </button>
                                <button className="btn btn-success" data-toggle="modal" data-target="#queryKeysModal" style={{ marginLeft: '10px' }}>
                                    <i className="fas fa-plus"></i> Query Keys
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-md-12">
                                <table
                                    id="keys_table"
                                    className="display"
                                    style={{ width: '100%' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Key</th>
                                            <th>Game</th>
                                            <th>Tier</th>
                                            <th>Created At</th>
                                            <th>Valid For</th>
                                            <th>Activated By</th>
                                            <th>Paused</th>
                                            <th>Note</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* ... rows go here */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    )
}