import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Menu from '../../components/menu';
import { toast } from 'react-toastify';

export default function Logs() {

    const [selectedLog, setSelectedLog] = useState({
        _id: '',
    });

    useEffect(() => {
        if (!$.fn.DataTable.isDataTable('#logs_table')) {
            $('#logs_table').DataTable({
                serverSide: true,
                processing: true,
                ajax: `${process.env.REACT_APP_API_URL}/logs/data_table`,
                columns: [
                    {
                        data: '_id'
                    },
                    {
                        data: 'level'
                    },
                    {
                        data: 'timestamp',
                        render: function (data, type, row) {
                            return moment(data).format('DD/MM/YYYY HH:mm');
                        }
                    },
                    {
                        data: 'message'
                    },
                    {
                        data: null,
                        render: (data, type, row) => {
                            return `
                          <button class="btn btn-danger delete-btn" data-id="${row._id}" data-toggle="modal" data-target="#deleteModal"><i class="fas fa-trash"></i></button>
                        `;
                        },
                        orderable: false
                    }
                ],
            });
        }
        $('#logs_table tbody').on('click', '.delete-btn', (e) => {
            const id = $(e.currentTarget).data('id');
            console.log(id);
            setSelectedLog(prevLog => ({
                ...prevLog,
                id
            }));
        });
    }, []);

    const handleDeleteLog = async () => {
        try {
            const response = await fetch('/api/logs/delete', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: selectedLog.id })
            });
            const result = await response.json();
            if (result.success) {
                // Refresh the DataTables and Close modal
                $('#logs_table').DataTable().ajax.reload();
                $('#deleteModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    }

    return (
        <div>
            <Header />
            <Menu />

            {/* Delete Modal */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this Log?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteLog}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h1 className="m-0 mr-3">Logs</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">

                        {/* Main row */}
                        <div className="row">
                            <div className="col-md-12">
                                <table
                                    id="logs_table"
                                    className="display"
                                    style={{ width: '100%' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Type</th>
                                            <th>Date</th>
                                            <th>Message</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* /.row (main row) */}

                    </div>{/* /.container-fluid */}
                </section>
            </div>
            <Footer />
        </div>
    );
}