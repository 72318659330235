import React, { useState, useEffect, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom';

import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Admins from './pages/admins';
import UserContext from './components/user';
import Logs from './pages/logs';
import Games from './pages/games';
import Keys from './pages/keys';
import Settings from './pages/settings';
import ViewAdmin from './pages/view/admin';
import { useSelector } from 'react-redux';

function LoadingAnim() {
  const fullText = "Loading...";
  const [displayedText, setDisplayedText] = useState("");
  const [isWriting, setIsWriting] = useState(true); // Flag to determine writing or erasing

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayedText((prevText) => {
        // If we're writing and haven't reached the end of the fullText
        if (isWriting && prevText.length < fullText.length) {
          return prevText + fullText[prevText.length];
        }
        // If we've reached the end of the fullText while writing
        else if (isWriting && prevText.length === fullText.length - 1) {
          setIsWriting(false);
          return prevText.slice(0, -1);
        }
        // If we're erasing and there are still characters left
        else if (!isWriting && prevText.length > 0) {
          return prevText.slice(0, -1);
        }
        // If we've erased all characters
        else {
          setIsWriting(true);
          return "";
        }
      });
    }, 100); // Adjust the time (in ms) to control the speed of the animation

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [isWriting]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="spinner-border" role="status" style={{ marginBottom: '20px' }}></div>
      <h6 style={{ height: '1.5rem' }}>{displayedText}</h6>
    </div>
  );
}

function GuardedComponent({ children }) {
  const authentication = useSelector((state) => state.auth.authentication);
  const { resources } = authentication || {};
  const location = useLocation();

  if (!authentication) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  switch (location.pathname) {
    case '/':
      return children;
    case '/login':
      return authentication ? <Navigate to="/" /> : <>{children}</>;
    default:
      for (const resourceItem of resources || []) {
        if (location.pathname.includes(resourceItem?.resource_name)) {
          return children;
        }
      }
      return <Navigate to="/" />;
  }
}

function NotFound() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',  // This ensures the div takes up the full viewport height
    }}>
      <div className="error-page">
        <h2 className="headline text-warning">404</h2>
        <div className="error-content">
          <h3><i className="fas fa-exclamation-triangle text-warning"></i> Oops! Page not found.</h3>
          <p>
            We could not find the page you were looking for.
            Meanwhile, you may <a href="/">return to dashboard</a>.
          </p>
        </div>
      </div>
    </div>
  );
}

function App() {
  const { loading } = useContext(UserContext);

  if (loading) return <LoadingAnim />;

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<GuardedComponent key="dashboard"><Dashboard /></GuardedComponent>} />
        <Route path="/admins" element={<GuardedComponent key="admins"><Admins /></GuardedComponent>} />
        <Route path="/games" element={<GuardedComponent key="games"><Games /></GuardedComponent>} />
        <Route path="/keys" element={<GuardedComponent key="keys"><Keys /></GuardedComponent>} />
        <Route path="/logs" element={<GuardedComponent key="logs"><Logs /></GuardedComponent>} />
        <Route path="/settings" element={<GuardedComponent key="settings"><Settings /></GuardedComponent>} />
        <Route path="/admins/view/:id" element={<GuardedComponent key="view_admin"><ViewAdmin/></GuardedComponent>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;