import React, { useState, useEffect } from 'react';
import UserContext from '../user';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthentication } from '../../store/auth';

function AuthProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const authentication = useSelector((state) => state.auth.authentication);

    useEffect(() => {
        checkSession();
    }, []);

    const checkSession = async () => {
        setLoading(true);
        // If no valid cache, proceed to make API call
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/checksession`);
            if (response.status === 200 && response.data.success) {
                const dataToCache = {
                    data: response.data.data,
                    timestamp: new Date().getTime()
                };
                localStorage.setItem('authData', JSON.stringify(dataToCache));
                dispatch(setAuthentication(response.data.data));
            } else {
                dispatch(setAuthentication(undefined));
            }
        } catch (error) {
            dispatch(setAuthentication(undefined));
        } finally {
            setLoading(false);
        }
    };

    const signIn = (username, password) => {
        return new Promise(async (resolve) => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signin`, {
                    username: username,
                    password: password
                });

                const data = response.data;
                // Check the success field of the returned JSON
                if (data.success) {
                    await checkSession();
                    toast.success("Authentication successful!", "Success");
                    resolve(true);
                } else {
                    // If success is false, show the provided error message
                    toast.error(data.message, "Error");
                    resolve(false);
                }
            } catch (error) {
                // Handle network errors or other unexpected issues
                toast.error("An error occurred. Please try again later.", "Error");
                console.log(error);
                resolve(false);
            }
        });
    };

    const signOut = () => {
        fetch(`${process.env.REACT_APP_API_URL}/auth/sign_out`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });
        localStorage.removeItem('authData');
        dispatch(setAuthentication(undefined));
    };

    const hasPermission = (actionName, resourceId) => {
        const { actions, permissions } = authentication || {};
        const actionId = actions?.find(action => action.action_name === actionName)?._id;
        if (!actionId) return false; // If the actionName doesn't exist, return false

        const permission = permissions?.find(permission =>
            permission.resource_id?.toString() === resourceId.toString() &&
            permission.action_id?.toString() === actionId.toString()
        );
        return permission?.has === true;
    };

    return (
        <UserContext.Provider value={{ authentication, loading, signIn, signOut, hasPermission, checkSession }}>
            {children}
        </UserContext.Provider>
    );
}

export default AuthProvider;