import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Menu from '../../components/menu';
import UserContext from '../../components/user';
import { toast } from 'react-toastify';

export default function Admins() {
    const location = useLocation();
    const navigate = useNavigate();

    const [roles, setRoles] = useState(null);
    const [games, setGames] = useState(null);
    const [selectedAdmin, setSelectedAdmin] = useState({
        _id: '',
        username: '',
        password: '',
        role: null,
        last_login: null,
        status: 0,
        credits: 0,
        games: [],
    });

    useEffect(() => {
        async function fetchRoles() {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/admins/roles`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();
                setRoles(result.roles);
            } catch (error) {
                toast.error(error, "Error");
            }
        }
        async function fetchGames() {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/games/list`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const result = await response.json();
                setGames(result.data);
            } catch (error) {
                toast.error(error, "Error");
            }
        }
        fetchRoles();
        fetchGames();
    }, []);

    useEffect(() => {
        if (!roles || games == null)
            return;

        if (!$.fn.DataTable.isDataTable('#admins_table')) {
            $('#admins_table').DataTable({
                serverSide: true,
                processing: true,
                ajax: `${process.env.REACT_APP_API_URL}/admins/data_table`,
                columns: [
                    { data: '_id', visible: false },
                    { data: 'username' },
                    {
                        data: 'role',
                        render: (data, type, row) => {
                            const roleObj = roles.find(role => role._id === data);
                            return roleObj ? roleObj.role_name : 'N/A';
                        }
                    },
                    { data: 'status' },
                    {
                        data: null,
                        render: (data, type, row) => {
                            return `
                            <button class="btn btn-primary view-btn" data-id="${row._id}"><i class="fas fa-eye"></i></button>
                            <button class="btn btn-danger delete-btn" data-id="${row._id}" data-toggle="modal" data-target="#deleteModal"><i class="fas fa-trash"></i></button>
                        `;
                        },
                        orderable: false
                    }
                ],
            });

            $('.select2').select2({
            }).on("change", function (e) {
                const selectedGameIds = $(this).val() || []; // Get the selected game IDs as an array

                // Update the games array in selectedAdmin based on the selected games
                setSelectedAdmin(prevState => ({
                    ...prevState,
                    games: selectedGameIds,
                }));
            });
        }
        $('#admins_table tbody').on('click', '.edit-btn', (e) => {
            const table = $('#admins_table').DataTable();
            const data = table.row($(e.currentTarget).parents('tr')).data();
            setSelectedAdmin({
                _id: data._id,
                username: data.username,
                password: '',
                role: data.role,
                last_login: data.last_login,
                status: data.status,
                manager: data.manager._id,
                credits: data.credits,
                games: data.games,
            });
            $('.select2').val(data.games).trigger('change');
        });
        $('#admins_table tbody').on('click', '.delete-btn', (e) => {
            const id = $(e.currentTarget).data('id');
            setSelectedAdmin(prevAdmin => ({
                ...prevAdmin,
                id
            }));
        });
        $('#admins_table tbody').on('click', '.view-btn', (e) => {
            const id = $(e.currentTarget).data('id');
            navigate(`/admins/view/${id}`);
        });
    }, [roles, games]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setSelectedAdmin(prevAdmin => ({
            ...prevAdmin,
            [name]: value
        }));
    }

    const password_show_hide = () => {
        let passwords = document.querySelectorAll(".password");
        let show_eyes = document.querySelectorAll(".show_eye");
        let hide_eyes = document.querySelectorAll(".hide_eye");

        // Check the type of the first password field to determine current state
        let currentType = passwords[0].type;

        // Loop through all password fields and toggle type
        passwords.forEach(pwd => {
            pwd.type = currentType === "password" ? "text" : "password";
        });

        if (currentType === "password") {
            show_eyes.forEach(eye => eye.style.display = "none");
            hide_eyes.forEach(eye => {
                eye.style.display = "block";
                eye.classList.remove("d-none");
            });
        } else {
            show_eyes.forEach(eye => eye.style.display = "block");
            hide_eyes.forEach(eye => {
                eye.style.display = "none";
                eye.classList.add("d-none");
            });
        }
    }

    const handleAddAdmin = async () => {
        try {
            const response = await fetch('/api/admins/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(selectedAdmin)
            });
            const result = await response.json();
            if (result.success) {
                // Refresh the DataTables and Close modal
                $('#admins_table').DataTable().ajax.reload();
                $('#addAdminModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    }

    const handleUpdateAdmin = async () => {
        try {
            const response = await fetch('/api/admins/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(selectedAdmin)
            });
            const result = await response.json();
            if (result.success) {
                // Refresh the DataTables and Close modal
                $('#admins_table').DataTable().ajax.reload();
                $('#editModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    }

    const handleDeleteAdmin = async () => {
        try {
            const response = await fetch('/api/admins/delete', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: selectedAdmin.id })
            });
            const result = await response.json();
            if (result.success) {
                // Refresh the DataTables and Close modal
                $('#admins_table').DataTable().ajax.reload();
                $('#deleteModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    }

    return (
        <div>
            <Header />
            <Menu />

            {/* Edit Modal */}
            <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Admin</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input type="text" name="username" value={selectedAdmin.username} onChange={handleInputChange} className="form-control" />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-lock"></i></span>
                                    </div>
                                    <input name="password" type="password" onChange={handleInputChange} className="input form-control password" placeholder="Current password is encrypted" required={true} aria-label="password" aria-describedby="basic-addon1" />
                                    <div className="input-group-append">
                                        <span className="input-group-text" onClick={password_show_hide}>
                                            <i className="fas fa-eye show_eye"></i>
                                            <i className="fas fa-eye-slash d-none hide_eye"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Role</label>
                                    <select
                                        name="role"
                                        value={roles && roles.some(role => role._id === selectedAdmin.role) ? selectedAdmin.role : ''}
                                        onChange={handleInputChange}
                                        className="form-control"
                                    >
                                        {roles && roles.map(role => (
                                            <option key={role._id} value={role._id}>{role.role_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <select name="status" value={selectedAdmin.status} onChange={handleInputChange} className="form-control">
                                        <option value="0">OK</option>
                                        <option value="1">BANNED</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Credits</label>
                                    <input type="text" name="credits" value={selectedAdmin.credits} onChange={handleInputChange} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Games</label>
                                    <div className="select2-purple">
                                        {(!Array.isArray(games) || games.length === 0) ? (
                                            <div>No games available.</div>
                                        ) : (
                                            <select
                                                className="select2 select2-hidden-accessible"
                                                multiple
                                                data-placeholder="Select the Games"
                                                data-dropdown-css-class="select2-purple"
                                                style={{ width: '100%' }}
                                                tabIndex="-1"
                                                aria-hidden="true"
                                            >
                                                {games.map(game => (
                                                    <option
                                                        key={game._id}
                                                        value={game._id}
                                                    >
                                                        {game.name} - {game.desc}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpdateAdmin}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Modal */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this Admin?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteAdmin}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Admin Modal */}
            <div className="modal fade" id="addAdminModal" tabIndex="-1" role="dialog" aria-labelledby="addAdminModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {/* ... */}
                        <div className="modal-body">
                            {/* Username */}
                            <div className="form-group">
                                <label>Username</label>
                                <input type="text" name="username" value={selectedAdmin.username} onChange={handleInputChange} className="form-control" required />
                            </div>

                            {/* Password */}
                            <div className="form-group">
                                <label>Password</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-lock"></i></span>
                                    </div>
                                    <input name="password" type="password" onChange={handleInputChange} className="input form-control password" required={true} aria-label="password" aria-describedby="basic-addon1" />
                                    <div className="input-group-append">
                                        <span className="input-group-text" onClick={password_show_hide}>
                                            <i className="fas fa-eye show_eye"></i>
                                            <i className="fas fa-eye-slash d-none hide_eye"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* Role */}
                            <div className="form-group">
                                <label>Role</label>
                                <select
                                    name="role"
                                    value={selectedAdmin.role || ''}
                                    onChange={handleInputChange}
                                    className="form-control"
                                >
                                    <option value="" disabled>Select Role</option>
                                    {roles && roles.map(role => (
                                        <option key={role._id} value={role._id}>{role.role_name}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Status */}
                            <div className="form-group">
                                <label>Status</label>
                                <select name="status" value={selectedAdmin.status} onChange={handleInputChange} className="form-control">
                                    <option value="0">OK</option>
                                    <option value="1">BANNED</option>
                                </select>
                            </div>

                            {/* Credits - Assuming you want to assign initial credits */}
                            <div className="form-group">
                                <label>Credits</label>
                                <input type="number" step="0.01" name="credits" value={selectedAdmin.credits} onChange={handleInputChange} className="form-control" placeholder="0.00" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddAdmin}>Add Admin</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h1 className="m-0 mr-3">Admins</h1>
                                <button className="btn btn-success" data-toggle="modal" data-target="#addAdminModal">
                                    <i className="fas fa-plus"></i> Add Admin
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">

                        {/* Main row */}
                        <div className="row">
                            <div className="col-md-12">
                                <table
                                    id="admins_table"
                                    className="display"
                                    style={{ width: '100%' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Username</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* /.row (main row) */}

                    </div>{/* /.container-fluid */}
                </section>
            </div>
            <Footer />
        </div>
    );
}