import React, { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Header from '../../components/header'
import Footer from '../../components/footer'
import Menu from '../../components/menu'
import UserContext from '../../components/user';
import { toast } from 'react-toastify';

export default function Dashboard() {

    const authentication = useSelector((state) => state.auth.authentication);
    const [usersData, setUsersData] = useState([]);
    const [subscriptionsData, setSubscriptionsData] = useState([]);
    const [gamesData, setGamesData] = useState([]);
    const [newUser, setNewUser] = useState({
        username: '',
        password: '',
        hwid: '',
        additional_hwid: '',
        status: 0,
    });
    const [selectedUser, setSelectedUser] = useState({
        _id: '',
        username: '',
        password: '',
        hwid: '',
        additional_hwid: '',
        ip: '',
        createdAt: null,
        createdBy: null,
        last_login: null,
        status: 0,
    });

    useEffect(() => {
        if (!$.fn.DataTable.isDataTable('#users_table')) {
            $('#users_table').DataTable({
                serverSide: true,
                processing: true,
                ajax: {
                    url: `${process.env.REACT_APP_API_URL}/users/data_table`,
                    type: 'GET'
                },
                columns: [
                    { data: '_id', visible: false },
                    { data: 'username' },
                    {
                        orderable: false,
                        data: 'ip'
                    },
                    {
                        data: 'createdAt',
                        render: function (data, type, row) {
                            return moment(data).format('DD/MM/YYYY HH:mm');
                        }
                    },
                    {
                        data: 'last_login',
                        render: function (data, type, row) {
                            return moment(data).fromNow();
                        }
                    },
                    {
                        data: 'status',
                        orderable: false,
                        render: function (data, type, row) {
                            return parseInt(data) > 0 ? "Banned" : "N/A";
                        }
                    },
                    {
                        data: null,
                        orderable: false,
                        render: function (data, type, row) {
                            const userId = data._id;

                            return `
                        <div class="btn-group">
                        <button type="button" class="btn btn-primary">Actions</button>
                        <button type="button" class="btn btn-primary dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                        <span class="sr-only">Actions</span>
                        </button>
                        <div class="dropdown-menu" role="menu" style="">
                        <a class="dropdown-item delete-user" href="#">Delete</a>
                        <a class="dropdown-item hwid-reset" href="#">Reset HWID</a>
                        <a class="dropdown-item pause-subscription" href="#">Pause</a>
                        <a class="dropdown-item unpause-subscription" href="#">Unpause</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item edit-btn" href="#" data-toggle="modal" data-target="#editUserModal">Edit</a>
                        </div>
                        </div>
                        `;
                        },
                    },
                ]
            });
            $('#users_table tbody').on('click', '.edit-btn', (e) => {
                const table = $('#users_table').DataTable();
                const data = table.row($(e.currentTarget).parents('tr')).data();
                setSelectedUser({
                    _id: data._id,
                    username: data.username,
                    password: data.password,
                    hwid: data.hwid,
                    additional_hwid: data.additional_hwid,
                    ip: data.ip,
                    createdAt: data.createdAt,
                    createdBy: data.createdBy,
                    last_login: data.last_login,
                    status: data.status,
                });
            });
            $('#users_table tbody').on('click', '.delete-user, .hwid-reset, .pause-subscription, .unpause-subscription', async (e) => {

                const actionEndpoints = {
                    'delete-user': 'delete',
                    'hwid-reset': 'reset_hwid',
                    'pause-subscription': 'pause_subscriptions',
                    'unpause-subscription': 'unpause_subscriptions',
                };

                const table = $('#users_table').DataTable();
                const data = table.row($(e.currentTarget).parents('tr')).data();

                const action = e.currentTarget.className.split(' ')[1]; // Assuming the action class is always the second one
                const endpointSuffix = actionEndpoints[action];

                if (!endpointSuffix) {
                    toast.error("Invalid action!", "Error");
                    return;
                }

                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${endpointSuffix}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            userId: data._id,
                        })
                    });
                    const result = await response.json();
                    if (result.success) {
                        $('#users_table').DataTable().ajax.reload();
                        toast.success(result.message, "Success");
                    } else {
                        toast.error(result.message, "Error");
                    }
                } catch (error) {
                    toast.error(error, "Error");
                }
            });

        }

        const fetchUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/list`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const result = await response.json();
                if (result && result.data) {
                    setUsersData(result.data);
                }
            } catch (error) {
                console.error("Error fetching users:", error);
                toast.error("Error fetching users");
            }
        }

        const fetchSubscriptions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/subscriptions/list`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const result = await response.json();
                if (result && result.data) {
                    setSubscriptionsData(result.data);
                }
            } catch (error) {
                console.error("Error fetching users:", error);
                toast.error("Error fetching users");
            }
        }

        const fetchGamesList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/games/list`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const result = await response.json();
                if (result && result.data) {
                    setGamesData(result.data);
                }
            } catch (error) {
                console.error("Error fetching games:", error);
                toast.error("Error fetching games");
            }
        }

        fetchUsers();
        fetchSubscriptions();
        fetchGamesList();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prevState => ({ ...prevState, [name]: value }));
    };

    const handleEditUserInputChange = (e) => {
        const { name, value } = e.target;

        setSelectedUser(prevAdmin => ({
            ...prevAdmin,
            [name]: value
        }));
    }

    const handleAddUser = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newUser)
            });
            const result = await response.json();
            if (result.success) {
                $('#addUserModal').modal('hide');
                window.location.reload();
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    };

    const deleteSubscription = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/subscriptions/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: id,
                })
            });
            const result = await response.json();
            if (result.success) {
                $('#editUserModal').modal('hide');
                window.location.reload();
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    };

    const saveCurrentUser = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _id: selectedUser._id,
                    username: selectedUser.username,
                    password: selectedUser.password,
                    status: selectedUser.status,
                    additional_hwid: selectedUser.additional_hwid
                })
            });
            const result = await response.json();
            if (result.success) {
                $('#editUserModal').modal('hide');
                window.location.reload();
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    };

    const password_show_hide = () => {
        let passwords = document.querySelectorAll(".password");
        let show_eyes = document.querySelectorAll(".show_eye");
        let hide_eyes = document.querySelectorAll(".hide_eye");

        // Check the type of the first password field to determine current state
        let currentType = passwords[0].type;

        // Loop through all password fields and toggle type
        passwords.forEach(pwd => {
            pwd.type = currentType === "password" ? "text" : "password";
        });

        if (currentType === "password") {
            show_eyes.forEach(eye => eye.style.display = "none");
            hide_eyes.forEach(eye => {
                eye.style.display = "block";
                eye.classList.remove("d-none");
            });
        } else {
            show_eyes.forEach(eye => eye.style.display = "block");
            hide_eyes.forEach(eye => {
                eye.style.display = "none";
                eye.classList.add("d-none");
            });
        }
    }

    return (
        <div>
            <Header />
            <Menu />

            <div className="modal fade" id="addUserModal" tabIndex="-1" role="dialog" aria-labelledby="addUserModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {/* ... */}
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input type="text" name="username" className="form-control" value={newUser.username} onChange={handleInputChange} />
                                </div>
                                <label>Password</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-lock"></i></span>
                                    </div>
                                    <input name="password" type="password" className="input form-control" id="password" placeholder="Current password is encrypted" required={true} aria-label="password" aria-describedby="basic-addon1" value={newUser.password} onChange={handleInputChange} />
                                    <div className="input-group-append">
                                        <span className="input-group-text" onClick={password_show_hide}>
                                            <i className="fas fa-eye" id="show_eye"></i>
                                            <i className="fas fa-eye-slash d-none hide_eye"></i>
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddUser}>Add User</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="editUserModal" tabIndex="-1" role="dialog" aria-labelledby="editUserModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit User</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input type="text" name="username" value={selectedUser.username} onChange={handleEditUserInputChange} className="form-control" />
                                </div>
                                <label>Password</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-lock"></i></span>
                                    </div>
                                    <input name="password" type="password" onChange={handleEditUserInputChange} className="input form-control password" id="password" placeholder="Current password is encrypted" required={true} aria-label="password" aria-describedby="basic-addon1" autoComplete='new-password' />
                                    <div className="input-group-append">
                                        <span className="input-group-text" onClick={password_show_hide}>
                                            <i className="fas fa-eye show_eye"></i>
                                            <i className="fas fa-eye-slash d-none hide_eye"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <input type="number" name="status" className="form-control" value={selectedUser.status} onChange={handleEditUserInputChange} />
                                </div>
                                <div className="form-group">
                                    <label>IP</label>
                                    <h6>{selectedUser.ip}</h6>
                                </div>
                                <div className="form-group">
                                    <label>HWID</label>
                                    <h6 style={{ width: '450px', whiteSpace: 'nowrap', overflow: 'hidden', 'text-overflow': 'ellipsis' }}>{selectedUser.hwid.length > 0 ? selectedUser.hwid : 'N/A'}</h6>
                                </div>
                                <div className="form-group">
                                    <label>Additional HWID</label>
                                    <input type="text" name="additional_hwid" placeholder='Enter HWID if you want this user to support multiple computers' onChange={handleEditUserInputChange} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Subscriptions</label>
                                    <div className="row">
                                        {subscriptionsData.length > 0 ? (
                                            subscriptionsData
                                                .filter(subscription => subscription.activatedBy === selectedUser._id && new Date(subscription.expiresAt) > new Date())
                                                .map(subscription => {
                                                    const game = gamesData.find(game => game._id === subscription.game);
                                                    return (
                                                        <div key={subscription._id} className="col-sm-4">
                                                            <blockquote className='quote-secondary'>
                                                                <p>{game ? game.name : 'Unknown'}</p>
                                                                <small>Expires on: {moment(subscription.expiresAt).format('DD/MM/YYYY')}</small>
                                                                <button onClick={() => deleteSubscription(subscription._id)} className="btn btn-danger btn-sm mt-2">Delete</button>
                                                            </blockquote>
                                                        </div>
                                                    );
                                                })
                                        ) : (
                                            <div className="col-12">
                                                <h6>N/A</h6>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={saveCurrentUser}>Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h1 className="m-0 mr-3">Dashboard</h1>
                                <button className="btn btn-success" data-toggle="modal" data-target="#addUserModal">
                                    <i className="fas fa-plus"></i> Add User
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{subscriptionsData.length}</h3>
                                        <p>Keys</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-key" />
                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{
                                            subscriptionsData ?
                                                subscriptionsData.filter(sub => sub.activated === true).length
                                                : 0
                                        }</h3>
                                        <p>Keys Sold</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-key" />
                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{usersData.length}</h3>
                                        <p>Users</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-user-plus" />
                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(authentication.user.credits)}
                                        </h3>
                                        <p>Credits</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-dollar" />
                                    </div>
                                </div>
                            </div>
                            {/* ./col */}
                        </div>
                        {/* /.row */}
                        {/* Main row */}
                        <div className="row">
                            <div className="col-md-12">
                                <table
                                    id="users_table"
                                    className="display"
                                    style={{ width: '100%' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Username</th>
                                            <th>IP</th>
                                            <th>Creation Date</th>
                                            <th>Last Login</th>
                                            <th>Banned</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* ... rows go here */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>{/* /.container-fluid */}
                </section>
            </div>

            <Footer />
        </div>
    );
}