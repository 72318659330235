import React, { useContext } from 'react';
import UserContext from '../user';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Menu() {
  const authentication = useSelector((state) => state.auth.authentication);
  const { hasPermission } = useContext(UserContext);
  const location = useLocation();
  const currentPath = location.pathname;
  let navItems = [];
  if (authentication != undefined && authentication.resources) {
    let resources = [...authentication.resources];
    navItems = resources
      .sort((a, b) => a.display.localeCompare(b.display))
      .map(resource => {
        if (resource.type !== "page" || !hasPermission('view', resource._id)) return null;
        return (
          <li key={resource._id} className="nav-item">
            <a href={`/${resource.path}`} className={`nav-link ${currentPath.includes(resource.path) ? 'active' : ''}`}>
              <i className={`nav-icon ${resource.icon}`}></i>
              <p>{resource.display}</p>
            </a>
          </li>
        );
      });
  }

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="/" className="brand-link">
        <img src="/assets/img/logoround.png" className="brand-image img-circle elevation-3" style={{ opacity: .8 }} alt="logo" />
        <span className="brand-text font-weight-light">License Manager</span>
      </a>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img src="/assets/img/user.png" className="img-circle elevation-2" alt="User" />
          </div>
          <div className="info">
            <a href="#" className="d-block">{authentication && authentication.user ? authentication.user.username : ''}</a>
          </div>
        </div>
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li className="nav-item">
              <a href="/" className={`nav-link ${currentPath === '/' ? 'active' : ''}`}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </a>
            </li>
            {navItems}
          </ul>
        </nav>
      </div>
    </aside>
  );
}