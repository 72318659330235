import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div>
        <aside className="control-sidebar control-sidebar-dark">
        </aside>
        <footer className="main-footer">
        <strong>Copyright &copy; 2017-2024 <span style={{ color: "#FF0099" }}>unkdev</span>. </strong>
          All rights reserved.
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 0.0.1
          </div>
        </footer>
      </div>
    )
  }
}
