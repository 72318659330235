import React, { useEffect, useState, useContext } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Menu from '../../components/menu';
import UserContext from '../../components/user';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export default function Settings() {
    const authentication = useSelector((state) => state.auth.authentication);
    const { hasPermission, checkSession } = useContext(UserContext);
    const deepCopy = obj => JSON.parse(JSON.stringify(obj));

    const [originalSettings, setOriginalSettings] = useState(deepCopy(Object.values(authentication.settings)));
    const [editableSettings, setEditableSettings] = useState(deepCopy(Object.values(authentication.settings)));
    const [hasChanged, setHasChanged] = useState(false);
    const [rolesPermissions, setRolesPermissions] = useState({
        roles: [],
        permissions: [],
        resources: [],
        actions: []
    });
    const [newResource, setNewResource] = useState({
        resource_name: '',
        display: '',
        type: '',
        icon: '',
        path: '',
    });

    const [currentRole, setCurrentRole] = useState(null);
    const [currentResource, setcurrentResource] = useState({
        resource_name: '',
        display: '',
        type: '',
        icon: '',
        path: '',
    });
    const [changedPermissions, setChangedPermissions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/settings/roles_permissions');
                if (response.ok) setRolesPermissions(await response.json());
                else toast.error('Failed to fetch roles and permissions data');
            } catch (error) {
                toast.error(`An error occurred: ${error.message}`);
            }
        };

        fetchData();
    }, [authentication, hasPermission]);

    useEffect(() => {
        // Using DataTables and jQuery
        if (!$.fn.DataTable.isDataTable('#roles') && rolesPermissions.roles.length > 0) {
            $('#roles').DataTable({
                data: rolesPermissions.roles,
                autoWidth: false,
                columns: [
                    { data: "_id", visible: false },
                    { data: "role_name", width: "90%" },
                    {
                        data: null,
                        width: "10%",
                        render: () => `
                        <button class="btn btn-primary edit-btn-roles" data-toggle="modal" data-target="#editModal"><i class="fas fa-pen"></i></button>
                        <button class="btn btn-danger delete-btn-roles" data-toggle="modal" data-target="#editModal"><i class="fas fa-trash"></i></button>
                        `
                    }
                ]
            });
            $('#roles tbody').on('click', '.edit-btn-roles', (e) => {
                const table = $('#roles').DataTable();
                const roleData = table.row($(e.currentTarget).parents('tr')).data();

                setCurrentRole({
                    _id: roleData._id,
                    role_name: roleData.role_name,
                });

                setChangedPermissions([]);
            });
            $('#roles tbody').on('click', '.delete-btn-roles', (e) => {

            });

            $('#editModal').on('hidden.bs.modal', () => setChangedPermissions([]));
        }

        if (!$.fn.DataTable.isDataTable('#resources') && rolesPermissions.roles.length > 0) {
            $('#resources').DataTable({
                data: rolesPermissions.resources,
                autoWidth: false,
                columns: [
                    { data: "_id", visible: false },
                    { data: "resource_name" },
                    { data: "display" },
                    { data: "type" },
                    { data: "icon" },
                    { data: "path" },
                    {
                        data: null,
                        width: "10%",
                        render: () => `
                    <button class="btn btn-primary edit-btn-roles" data-toggle="modal" data-target="#editResource"><i class="fas fa-pen"></i></button>
                    <button class="btn btn-danger delete-btn-roles" data-toggle="modal" data-target="#deleteResource"><i class="fas fa-trash"></i></button>
                    `
                    }
                ]
            });
            $('#resources tbody').on('click', '.edit-btn-roles', (e) => {
                const table = $('#resources').DataTable();
                const resourceData = table.row($(e.currentTarget).parents('tr')).data();

                setcurrentResource({
                    _id: resourceData._id,
                    resource_name: resourceData.resource_name,
                    display: resourceData.display,
                    type: resourceData.type,
                    icon: resourceData.icon,
                    path: resourceData.path,
                });
            });
            $('#resources tbody').on('click', '.delete-btn-roles', (e) => {

            });
        }

    }, [rolesPermissions]);

    const handleInputChange = (index, e) => {
        setEditableSettings(prevSettings => {
            const newSettings = deepCopy(prevSettings);
            let newValue;
            if (newSettings[index].type === 'boolean') {
                newValue = e.target.value === 'true'; // convert string to boolean
            } else {
                newValue = e.target.value;
            }
            newSettings[index].data = newValue;
            if (originalSettings[index].data !== newValue) {
                setHasChanged(true);
            }
            return newSettings;
        });
    };

    const hasPermissionForAction = (roleId, resourceId, actionId) => {
        const changedPermission = changedPermissions.find(p =>
            p.role_id.toString() === roleId.toString() &&
            p.resource_id.toString() === resourceId.toString() &&
            p.action_id.toString() === actionId.toString()
        );

        if (changedPermission) return changedPermission.has;
        const originalPermission = rolesPermissions.permissions.find(p =>
            p.role_id.toString() === roleId.toString() &&
            p.resource_id.toString() === resourceId.toString() &&
            p.action_id.toString() === actionId.toString()
        );

        return originalPermission ? originalPermission.has : false;
    };

    const handleCheckboxChange = (roleId, resourceId, actionId, checked) => {
        const existingPermissionIndex = changedPermissions.findIndex(p =>
            p.role_id.toString() === roleId.toString() &&
            p.resource_id.toString() === resourceId.toString() &&
            p.action_id.toString() === actionId.toString()
        );

        if (existingPermissionIndex !== -1) {
            setChangedPermissions(prev => {
                const updated = [...prev];
                updated[existingPermissionIndex].has = checked;
                return updated;
            });
        } else {
            const permission = rolesPermissions.permissions.find(p =>
                p.role_id.toString() === roleId.toString() &&
                p.resource_id.toString() === resourceId.toString() &&
                p.action_id.toString() === actionId.toString()
            );
            setChangedPermissions(prev => [...prev, { ...permission, has: checked }]);
        }
    };

    const saveGeneralSettings = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/update_role_permissions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(changedPermissions)
            });

            const result = await response.json();
            if (result.success) {
                checkSession(true);
                toast.success(result.message);
                $('#editModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    };

    const saveGeneralTabSettings = async () => {
        if (!hasChanged) {
            toast.info("No changes made to the settings.");
            return;
        }

        const changedSettings = editableSettings.filter((setting, index) => {
            return setting.data !== originalSettings[index].data;
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/save_general`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(changedSettings)
            });

            const result = await response.json();
            if (result.success) {
                checkSession(true);
                setHasChanged(false);  // reset the hasChanged flag
                setOriginalSettings([...editableSettings]); // update the original settings to the newly saved settings
                toast.success(result.message);
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
        
    };

    const handleInputResourceChange = (e) => {
        console.log(e);
        setNewResource(prevResource => {
            const newResource = { ...prevResource };
            newResource[e.target.name] = e.target.value;
            return newResource;
        });
    };

    const createResource = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/settings/create_resource`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newResource)
            });
            const result = await response.json();
            if (result.success) {
                $('#createResource').modal('hide');
                window.location.reload();
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    };

    return (
        <div>
            <Header />
            <Menu />

            <div className="modal fade" id="editResource" tabIndex="-1" role="dialog" aria-labelledby="editResourceLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit {currentResource ? currentResource.resource_name : ''} resource</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" name="resource_name" className="form-control" value={currentResource.resource_name} onChange={handleInputResourceChange} />
                            </div>
                            <div className="form-group">
                                <label>Display</label>
                                <input type="text" name="display" className="form-control" value={currentResource.display} onChange={handleInputResourceChange} />
                            </div>
                            <div className="form-group">
                                <label>Type</label>
                                <select name="type" className="form-control"
                                    value={currentResource.type}
                                    onChange={handleInputResourceChange}>
                                    <option>page</option>
                                    <option>comp</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Icon</label>
                                <input type="text" name="icon" className="form-control" value={currentResource.icon} onChange={handleInputResourceChange} />
                            </div>
                            <div className="form-group">
                                <label>Path</label>
                                <input type="text" name="path" className="form-control" value={currentResource.path} onChange={handleInputResourceChange} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={createResource}>Create</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="createResource" tabIndex="-1" role="dialog" aria-labelledby="createResourceLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" name="resource_name" className="form-control" value={newResource.resource_name} onChange={handleInputResourceChange} />
                            </div>
                            <div className="form-group">
                                <label>Display</label>
                                <input type="text" name="display" className="form-control" value={newResource.display} onChange={handleInputResourceChange} />
                            </div>
                            <div className="form-group">
                                <label>Type</label>
                                <select name="type" className="form-control"
                                    value={newResource.type}
                                    onChange={handleInputResourceChange}>
                                    <option>page</option>
                                    <option>comp</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Icon</label>
                                <input type="text" name="icon" className="form-control" value={newResource.icon} onChange={handleInputResourceChange} />
                            </div>
                            <div className="form-group">
                                <label>Path</label>
                                <input type="text" name="path" className="form-control" value={newResource.path} onChange={handleInputResourceChange} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={createResource}>Create</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit {currentRole ? currentRole.role_name : ''}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                {currentRole && (
                                    <div key={currentRole._id}>
                                        <h4>{currentRole.role_name}</h4>
                                        {rolesPermissions.resources.map((resource, index) => (
                                            <div key={resource._id} className="form-group">
                                                <label>{resource.resource_name}</label>
                                                <div>
                                                    {rolesPermissions.actions.map((action) => (
                                                        <div key={action._id} className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`role-${currentRole._id}-resource-${index}-action-${action._id}`}
                                                                value={action.action_name}
                                                                checked={hasPermissionForAction(currentRole._id, resource._id, action._id)}
                                                                onChange={(e) => handleCheckboxChange(currentRole._id, resource._id, action._id, e.target.checked)}
                                                            />
                                                            <label className="form-check-label" htmlFor={`role-${currentRole._id}-resource-${index}-action-${action._id}`}>
                                                                {action.action_name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={saveGeneralSettings}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">

                        <div className="card card-pink card-tabs">
                            <div className="card-header p-0 pt-1">
                                <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="custom-tabs-one-general-tab" data-toggle="pill" href="#custom-tabs-one-general" role="tab" aria-controls="custom-tabs-one-general" aria-selected="true">General</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-one-permissions-tab" data-toggle="pill" href="#custom-tabs-one-permissions" role="tab" aria-controls="custom-tabs-one-permissions" aria-selected="false">Permissions</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="custom-tabs-one-resources-tab" data-toggle="pill" href="#custom-tabs-one-resources" role="tab" aria-controls="custom-tabs-one-resources" aria-selected="false">Resources</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-one-tabContent">
                                    <div className="tab-pane fade active show" id="custom-tabs-one-general" role="tabpanel" aria-labelledby="custom-tabs-one-general-tab">
                                        {editableSettings.map((setting, index) => (
                                            <div className="form-group row" key={index}>
                                                <label htmlFor={`setting-${setting.name}`} className="col-2 col-form-label">{setting.display}</label>
                                                <div className="col-10">
                                                    {setting.type === 'String' && (
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id={`setting-${setting.name}`}
                                                            value={setting.data}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            placeholder={`Enter ${setting.name}...`}
                                                            required={setting.required}
                                                        />
                                                    )}
                                                    {setting.type === 'Number' && (
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            id={`setting-${setting.name}`}
                                                            value={setting.data}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            placeholder={`Enter ${setting.name}...`}
                                                            required={setting.required}
                                                        />
                                                    )}
                                                    {setting.type === 'Boolean' && (
                                                        <select
                                                            className="form-control"
                                                            id={`setting-${setting.name}`}
                                                            value={setting.data.toString()}
                                                            onChange={(e) => handleInputChange(index, e)}>
                                                            <option value="true">True</option>
                                                            <option value="false">False</option>
                                                        </select>
                                                    )}
                                                    {setting.type === 'Array' && (
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id={`setting-${setting.name}`}
                                                            value={setting.data.join(', ')} // Assuming you're using a simple array of strings or numbers
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            placeholder={`Enter ${setting.name} as comma-separated values...`}
                                                            required={setting.required}
                                                        />
                                                    )}
                                                    {setting.type === 'Object' && (
                                                        <textarea
                                                            className="form-control"
                                                            id={`setting-${setting.name}`}
                                                            value={JSON.stringify(setting.data, null, 2)}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            placeholder={`Enter ${setting.name} as JSON...`}
                                                            required={setting.required}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                        <div className="form-group row">
                                            <div className="col-12 text-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={saveGeneralTabSettings}
                                                    disabled={!hasChanged}
                                                >
                                                    Save Settings
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-permissions" role="tabpanel" aria-labelledby="custom-tabs-one-permissions-tab">
                                        <div>
                                            <button className="btn btn-success mr-3" data-toggle="modal" data-target="#createRole">
                                                <i className="fas fa-plus"></i> Role
                                            </button>
                                        </div>
                                        <br /><br />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table
                                                    id="roles"
                                                    className="display"
                                                    style={{ width: '100%' }}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th style={{ width: '90%' }}>Name</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-resources" role="tabpanel" aria-labelledby="custom-tabs-one-resources-tab">
                                        <div>
                                            <button className="btn btn-success" data-toggle="modal" data-target="#createResource">
                                                <i className="fas fa-plus"></i> Create Resource
                                            </button>
                                        </div>
                                        <br /><br />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <table
                                                    id="resources"
                                                    className="display"
                                                    style={{ width: '100%' }}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Name</th>
                                                            <th>Display</th>
                                                            <th>Type</th>
                                                            <th>Icon</th>
                                                            <th>Path</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>{/* /.container-fluid */}
                </section>
            </div>
            <Footer />
        </div>
    )
}