import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Menu from '../../components/menu';
import { toast } from 'react-toastify';

export default function Games() {

    const [selectedGame, setSelectedGame] = useState({
        id: '',
        name: '',
        desc: '',
        img_url: '',
        status: 'ONLINE',
        tiers: [
        ],
        key_mask: '',
    });

    useEffect(() => {
        if (!$.fn.DataTable.isDataTable('#games_table')) {
            $('#games_table').DataTable({
                serverSide: true,
                processing: true,
                ajax: `${process.env.REACT_APP_API_URL}/games/data_table`,
                columns: [
                    { data: '_id', visible: false },
                    { data: 'name' },
                    { data: 'desc' },
                    {
                        data: 'img_url', render: function (data, type, row) {
                            return `<img src="${data}" width="80" height="80" />`;
                        }
                    },
                    { data: 'status' },
                    { data: 'key_mask' },
                    {
                        data: null,
                        render: (data, type, row) => {
                            return `
                          <button class="btn btn-primary edit-btn" data-id="${row.id}" data-toggle="modal" data-target="#editModal"><i class="fas fa-edit"></i></button>
                          <button class="btn btn-danger delete-btn" data-id="${row._id}" data-toggle="modal" data-target="#deleteModal"><i class="fas fa-trash"></i></button>
                        `;
                        },
                        orderable: false
                    },
                    {
                        data: 'tiers',
                        visible: false
                    },
                ],
            });
        }
        $('#games_table tbody').on('click', '.edit-btn', (e) => {
            const table = $('#games_table').DataTable();
            const data = table.row($(e.currentTarget).parents('tr')).data();

            setSelectedGame({
                id: data._id,
                name: data.name,
                desc: data.desc,
                img_url: data.img_url,
                status: data.status,
                tiers: data.tiers,
                key_mask: data.key_mask,
            });
        });
        $('#games_table tbody').on('click', '.delete-btn', (e) => {
            const id = $(e.currentTarget).data('id');
            setSelectedGame(prevGame => ({
                ...prevGame,
                id
            }));
        });
    }, []);

    const handleTierChange = (e, index, type) => {
        const newTiers = [...selectedGame.tiers];
        let value = e.target.value;

        if (type === 'name') {
            newTiers[index].id = value;
        } else {
            newTiers[index].prices[type] = value ? parseFloat(value) : 0; // or '' if you want to set an empty string instead of 0
        }

        setSelectedGame(prevGame => ({
            ...prevGame,
            tiers: newTiers
        }));
    }

    const addTier = () => {
        setSelectedGame(prevGame => ({
            ...prevGame,
            tiers: [...(prevGame.tiers || []), {
                id: '',
                prices: {
                    day: 0,
                    week: 0,
                    month: 0,
                    lifetime: 0,
                }
            }]
        }));
    }

    const removeTier = (indexToRemove) => {
        setSelectedGame(prevState => ({
            ...prevState,
            tiers: prevState.tiers.filter((_, index) => index !== indexToRemove)
        }));
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setSelectedGame(prevGame => ({
            ...prevGame,
            [name]: value
        }));
    }

    const handleAddGame = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/games/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(selectedGame)
            });
            const result = await response.json();
            if (result.success) {
                // Refresh the DataTables and Close modal
                $('#games_table').DataTable().ajax.reload();
                $('#addGameModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    }

    const handleUpdateGame = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/games/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: selectedGame.id,
                    ...selectedGame
                })
            });
            const result = await response.json();
            if (result.success) {
                // Refresh the DataTables and Close modal
                $('#games_table').DataTable().ajax.reload();
                $('#editModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    }

    const handleDeleteGame = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/games/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: selectedGame.id })
            });
            const result = await response.json();
            if (result.success) {
                // Refresh the DataTables and Close modal
                $('#games_table').DataTable().ajax.reload();
                $('#deleteModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    }

    return (
        <div>
            <Header />
            <Menu />

            {/* Edit Modal */}
            <div className="modal fade" id="editModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Game</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" name="name" value={selectedGame.name} onChange={handleInputChange} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea name="desc" value={selectedGame.desc} onChange={handleInputChange} className="form-control" ></textarea>
                                </div>
                                <div className="form-group">
                                    <label>Image URL</label>
                                    <input type="text" name="img_url" value={selectedGame.img_url} onChange={handleInputChange} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <select name="status" value={selectedGame.status} onChange={handleInputChange} className="form-control" >
                                        <option value="ONLINE">ONLINE</option>
                                        <option value="OFFLINE">OFFLINE</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Tiers</label>
                                    <div><button type="button" className="btn btn-success btn-sm" onClick={addTier}>+ Add Tier</button></div>
                                    {selectedGame.tiers.map((tier, index) => (
                                        <div key={index} className="mb-3">
                                            <div className="mb-2">
                                                <label className="mr-2">Tier Name:</label>
                                                <input
                                                    type="text"
                                                    placeholder="Tier Name"
                                                    value={tier.id}
                                                    className="form-control"
                                                    onChange={e => handleTierChange(e, index, 'name')}
                                                />
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                {/* ...Tier pricing fields... */}
                                                <div className="d-flex flex-wrap">
                                                    <div className="mr-3 mb-2">
                                                        <label>Day Price:</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Day Price"
                                                            value={tier.prices.day}
                                                            min={0}
                                                            className="form-control"
                                                            onChange={e => handleTierChange(e, index, 'day')}
                                                        />
                                                    </div>
                                                    <div className="mr-3 mb-2">
                                                        <label>Week Price:</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Week Price"
                                                            value={tier.prices.week}
                                                            className="form-control"
                                                            onChange={e => handleTierChange(e, index, 'week')}
                                                        />
                                                    </div>
                                                    <div className="mr-3 mb-2">
                                                        <label>Month Price:</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Month Price"
                                                            value={tier.prices.month}
                                                            className="form-control"
                                                            onChange={e => handleTierChange(e, index, 'month')}
                                                        />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label>Lifetime Price:</label>
                                                        <input
                                                            type="number"
                                                            placeholder="Lifetime Price"
                                                            value={tier.prices.lifetime}
                                                            className="form-control"
                                                            onChange={e => handleTierChange(e, index, 'lifetime')}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Key Mask</label>
                                                        <input type="text" name="key_mask" value={selectedGame.key_mask} onChange={handleInputChange} className="form-control" />
                                                    </div>
                                                </div>
                                                {/* ...other tier pricing fields... */}
                                            </div>
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => removeTier(index)}>Remove</button>
                                        </div>
                                    ))}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpdateGame}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Modal */}
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this game?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteGame}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Game Modal */}
            <div className="modal fade" id="addGameModal" tabIndex="-1" role="dialog" aria-labelledby="addGameModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {/* ... */}
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" name="name" className="form-control" onChange={handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea name="desc" className="form-control" onChange={handleInputChange}></textarea>
                                </div>
                                <div className="form-group">
                                    <label>Image URL</label>
                                    <input type="text" name="img_url" className="form-control" onChange={handleInputChange} />
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <select name="status" className="form-control" onChange={handleInputChange}>
                                        <option value="ONLINE">ONLINE</option>
                                        <option value="OFFLINE">OFFLINE</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Tiers</label>
                                    <div><button type="button" className="btn btn-success btn-sm" onClick={addTier}>+ Add Tier</button></div>
                                    {selectedGame.tiers.map((tier, index) => (
                                        <div key={index} className="mb-3">
                                            <div className="mb-2">
                                                <label className="mr-2">Tier Name:</label>
                                                <input
                                                    type="text"
                                                    placeholder="Tier Name"
                                                    value={tier.id}
                                                    className="form-control"
                                                    onChange={e => handleTierChange(e, index, 'name')}
                                                />
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <div className="mr-3 mb-2">
                                                    <label>Day Price:</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Day Price"
                                                        value={tier.prices.day}
                                                        className="form-control"
                                                        onChange={e => handleTierChange(e, index, 'day')}
                                                    />
                                                </div>
                                                <div className="mr-3 mb-2">
                                                    <label>Week Price:</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Week Price"
                                                        value={tier.prices.week}
                                                        className="form-control"
                                                        onChange={e => handleTierChange(e, index, 'week')}
                                                    />
                                                </div>
                                                <div className="mr-3 mb-2">
                                                    <label>Month Price:</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Month Price"
                                                        value={tier.prices.month}
                                                        className="form-control"
                                                        onChange={e => handleTierChange(e, index, 'month')}
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <label>Lifetime Price:</label>
                                                    <input
                                                        type="number"
                                                        placeholder="Lifetime Price"
                                                        value={tier.prices.lifetime}
                                                        className="form-control"
                                                        onChange={e => handleTierChange(e, index, 'lifetime')}
                                                    />
                                                </div>
                                            </div>
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => removeTier(index)}>Remove</button>
                                        </div>
                                    ))}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleAddGame}>Add Game</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h1 className="m-0 mr-3">Games</h1>
                                <button className="btn btn-success" data-toggle="modal" data-target="#addGameModal">
                                    <i className="fas fa-plus"></i> Add Game
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">

                        {/* Main row */}
                        <div className="row">
                            <div className="col-md-12">
                                <table
                                    id="games_table"
                                    className="display"
                                    style={{ width: '100%' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Desc</th>
                                            <th>Image</th>
                                            <th>Status</th>
                                            <th>Mask</th>
                                            <th>Actions</th>
                                            <th>tiers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* /.row (main row) */}

                    </div>{/* /.container-fluid */}
                </section>
            </div>
            <Footer />
        </div>
    );
}