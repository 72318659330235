import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Menu from '../../../components/menu';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function ViewAdmin() {
    const { id } = useParams();
    const [subscriptionsData, setSubscriptionsData] = useState([]);
    const [gamesData, setGamesData] = useState([]);
    const [usersData, setUsersData] = useState([null]);
    const [adminData, setAdminData] = useState([]);
    const [roles, setRoles] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState({
        id: '',
    });

    useEffect(() => {
        const fetchData = async (url, method = 'POST', body = null) => {
            try {
                const options = {
                    method,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                if (body) {
                    options.body = JSON.stringify(body);
                }
                const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, options);
                const result = await response.json();
                return result;
            } catch (error) {
                console.error(`Error fetching ${url}:`, error);
                toast.error(`Error fetching ${url}`);
            }
        };
    
        const fetchAdmin = async () => {
            const result = await fetchData('/admins/get_admin', 'POST', { id });
            if (result) {
                setAdminData(result);
            }
        };
    
        const fetchRoles = async () => {
            const result = await fetchData('/admins/roles');
            if (result && result.roles) {
                setRoles(result.roles);
            }
        };
    
        const fetchSubscriptions = async () => {
            const result = await fetchData('/subscriptions/list', 'POST', { id });
            if (result && result.data) {
                setSubscriptionsData(result.data);
            }
        };
    
        const fetchUsers = async () => {
            const result = await fetchData('/users/list', 'POST', { id });
            if (result && result.data) {
                setUsersData(result.data);
            }
        };
    
        const fetchGamesList = async () => {
            const result = await fetchData('/games/list', 'GET');
            if (result && result.data) {
                setGamesData(result.data);
            }
        };
    
        Promise.all([
            fetchAdmin(),
            fetchRoles(),
            fetchSubscriptions(),
            fetchUsers(),
            fetchGamesList(),
        ]);
    }, []);

    useEffect(() => {

        if (adminData.length === 0)
            return;

        if (!$.fn.DataTable.isDataTable('#users_table')) {
            $('#users_table').DataTable({
                serverSide: true,
                processing: true,
                ajax: {
                    url: `${process.env.REACT_APP_API_URL}/users/data_table?createdBy=${adminData._id}`,
                    type: 'GET'
                },
                //ajax: `${process.env.REACT_APP_API_URL}/users/data_table`,
                columns: [
                    { data: '_id', visible: false },
                    { data: 'username' },
                    {
                        orderable: false,
                        data: 'ip'
                    },
                    {
                        data: 'createdAt',
                        render: function (data, type, row) {
                            return moment(data).format('DD/MM/YYYY HH:mm');
                        }
                    },
                    {
                        data: 'last_login',
                        render: function (data, type, row) {
                            return moment(data).fromNow();
                        }
                    },
                    {
                        data: 'status',
                        orderable: false,
                        render: function (data, type, row) {
                            return parseInt(data) > 0 ? "Banned" : "N/A";
                        }
                    },
                    {
                        data: null,
                        orderable: false,
                        render: function (data, type, row) {
                            const userId = data._id;

                            return `
                        <div class="btn-group">
                        <button type="button" class="btn btn-primary">Actions</button>
                        <button type="button" class="btn btn-primary dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                        <span class="sr-only">Actions</span>
                        </button>
                        <div class="dropdown-menu" role="menu" style="">
                        <a class="dropdown-item delete-user" href="#">Delete</a>
                        <a class="dropdown-item hwid-reset" href="#">Reset HWID</a>
                        <a class="dropdown-item pause-subscription" href="#">Pause</a>
                        <a class="dropdown-item unpause-subscription" href="#">Unpause</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item edit-btn" href="#" data-toggle="modal" data-target="#editUserModal">Edit</a>
                        </div>
                        </div>
                        `;
                        },
                    },
                ]
            });
            $('#users_table tbody').on('click', '.edit-btn', (e) => {
                const table = $('#users_table').DataTable();
                const data = table.row($(e.currentTarget).parents('tr')).data();
                setSelectedUser({
                    _id: data._id,
                    username: data.username,
                    password: data.password,
                    hwid: data.hwid,
                    additional_hwid: data.additional_hwid,
                    ip: data.ip,
                    createdAt: data.createdAt,
                    createdBy: data.createdBy,
                    last_login: data.last_login,
                    status: data.status,
                });
            });
            $('#users_table tbody').on('click', '.delete-user, .hwid-reset, .pause-subscription, .unpause-subscription', async (e) => {

                const actionEndpoints = {
                    'delete-user': 'delete',
                    'hwid-reset': 'reset_hwid',
                    'pause-subscription': 'pause_subscriptions',
                    'unpause-subscription': 'unpause_subscriptions',
                };

                const table = $('#users_table').DataTable();
                const data = table.row($(e.currentTarget).parents('tr')).data();

                const action = e.currentTarget.className.split(' ')[1]; // Assuming the action class is always the second one
                const endpointSuffix = actionEndpoints[action];

                if (!endpointSuffix) {
                    toast.error("Invalid action!", "Error");
                    return;
                }

                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${endpointSuffix}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            userId: data._id,
                        })
                    });
                    const result = await response.json();
                    if (result.success) {
                        $('#users_table').DataTable().ajax.reload();
                        toast.success(result.message, "Success");
                    } else {
                        toast.error(result.message, "Error");
                    }
                } catch (error) {
                    toast.error(error, "Error");
                }
            });
        }
        if (gamesData.length > 0 && !$.fn.DataTable.isDataTable('#keys_table')) {
            $('#keys_table').DataTable({
                serverSide: true,
                processing: true,
                ajax: {
                    url: `${process.env.REACT_APP_API_URL}/subscriptions/data_table?createdBy=${adminData._id}`,
                    type: 'GET'
                },
                columns: [
                    { data: '_id', visible: false },
                    { data: 'key' },
                    {
                        data: 'game',
                        render: (data, type, row) => {
                            const game = gamesData.find(game => game._id === data);
                            return game ? game.name : '';
                        },
                    },
                    { data: 'tier' },
                    {
                        data: 'createdAt',
                        render: (data, type, row) => {
                            return moment(data).format('DD/MM/YYYY HH:mm');
                        }
                    },
                    {
                        data: 'validFor',
                        render: (data) => {
                            const duration = moment.duration(data, 'seconds');

                            const months = duration.months();
                            let remainingDays = duration.days();
                            const weeks = Math.floor(remainingDays / 7);
                            remainingDays %= 7;

                            let output = "";

                            if (months > 0) {
                                output += `${months} Month${months > 1 ? 's' : ''} `;
                            }
                            if (weeks > 0) {
                                output += `${weeks} Week${weeks > 1 ? 's' : ''} `;
                            }
                            if (remainingDays > 0) {
                                output += `${remainingDays}D`;
                            }

                            return output.trim();
                        },
                    },
                    {
                        data: 'activatedBy',
                        render: (data, type, row) => {
                            return data ? data : 'N/A';
                        }
                    },
                    {
                        data: 'paused',
                        render: (data, type, row) => {
                            return data ? 'YES' : 'NO';
                        }
                    },
                    {
                        data: 'note',
                        render: (data, type, row) => {
                            return data;
                        }
                    },
                    {
                        data: null,
                        render: (data, type, row) => {
                            return `
                          <button class="btn btn-danger delete-btn" data-id="${row._id}" data-toggle="modal" data-target="#deleteKeyModal"><i class="fas fa-trash"></i></button>
                        `;
                        },
                        orderable: false
                    },
                ],
                columnDefs: [
                    { width: "13%", targets: 1 },
                    { width: "13%", targets: 6 },
                    { width: "3%", targets: 7 },
                    { width: "15%", targets: 8 },
                    { width: "2%", targets: 9 }
                ]
            });

            $('#keys_table tbody').on('click', '.delete-btn', (e) => {
                const id = $(e.currentTarget).data('id');
                setSelectedSubscription(prevSubscription => ({
                    ...prevSubscription,
                    id
                }));
            });

            $('#keys_stats').DataTable({
                serverSide: true,
                processing: true,
                ajax: {
                    url: `${process.env.REACT_APP_API_URL}/subscriptions/data_table_statistics?createdBy=${adminData._id}`,
                    type: 'GET'
                },
                columns: [
                    {
                        data: 'Date',
                        render: (data, type, row) => {
                            return moment(data).format('DD/MM/YYYY');
                        }
                    },
                    { data: 'Tier' },
                    { data: 'TotalSold' },
                    { data: 'Days' },
                    { data: 'Weeks' },
                    { data: 'Months' },
                    { data: 'Lifetime' },
                ]
            });
        }
    }, [adminData, gamesData]);

    const onAdminInputChange = (e) => {
        const { name, value } = e.target;
        setAdminData(prevAdmin => ({
            ...prevAdmin,
            [name]: value
        }));
    }

    const handleUpdateAdmin = async () => {
        try {
            const response = await fetch('/api/admins/update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(adminData)
            });
            const result = await response.json();

            if (result.success) {
                toast.success('Admin updated with success!');
                $('#editModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    }

    const handleDeleteSubscription = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/subscriptions/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: selectedSubscription.id })
            });
            const result = await response.json();
            if (result.success) {
                // Refresh the DataTables and Close modal
                $('#keys_table').DataTable().ajax.reload();
                $('#deleteKeyModal').modal('hide');
            } else {
                toast.error(result.message, "Error");
            }
        } catch (error) {
            toast.error(error, "Error");
        }
    };

    const password_show_hide = () => {
        let passwords = document.querySelectorAll(".password");
        let show_eyes = document.querySelectorAll(".show_eye");
        let hide_eyes = document.querySelectorAll(".hide_eye");

        // Check the type of the first password field to determine current state
        let currentType = passwords[0].type;

        // Loop through all password fields and toggle type
        passwords.forEach(pwd => {
            pwd.type = currentType === "password" ? "text" : "password";
        });

        if (currentType === "password") {
            show_eyes.forEach(eye => eye.style.display = "none");
            hide_eyes.forEach(eye => {
                eye.style.display = "block";
                eye.classList.remove("d-none");
            });
        } else {
            show_eyes.forEach(eye => eye.style.display = "block");
            hide_eyes.forEach(eye => {
                eye.style.display = "none";
                eye.classList.add("d-none");
            });
        }
    }

    return (
        <div>
            <Header />
            <Menu />

            {/* Delete Key Modal */}
            <div className="modal fade" id="deleteKeyModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to delete this Key?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={handleDeleteSubscription}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Modal */}
            <div className="modal fade" id="editAdminModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Admin</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input key="username_edit_admin" type="text" id="username_edit_admin" name="username_edit_admin" value={adminData.username || ''} onChange={onAdminInputChange} className="form-control" autoComplete="off" />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-lock"></i></span>
                                    </div>
                                    <input key="password_edit_admin" name="password_edit_admin" type="password" onChange={onAdminInputChange} className="input form-control password" placeholder="Current password is encrypted" required={true} aria-label="Edit Admin Password" aria-describedby="Edit Admin Password" autoComplete="new-password" />
                                    <div className="input-group-append">
                                        <span className="input-group-text" onClick={password_show_hide}>
                                            <i className="fas fa-eye show_eye"></i>
                                            <i className="fas fa-eye-slash d-none hide_eye"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Role</label>
                                    <select
                                        name="role"
                                        value={roles && roles.some(role => role._id === adminData.role) ? adminData.role : ''}
                                        onChange={onAdminInputChange}
                                        className="form-control"
                                    >
                                        {roles && roles.map(role => (
                                            <option key={role._id} value={role._id}>{role.role_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <select name="status" value={adminData.status || ''} onChange={onAdminInputChange} className="form-control">
                                        <option value="0">OK</option>
                                        <option value="1">BANNED</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Credits</label>
                                    <input type="text" name="credits" value={adminData.credits || ''} onChange={onAdminInputChange} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Games</label>
                                    <div className="select2-purple">
                                        {(!Array.isArray(gamesData) || gamesData.length === 0) ? (
                                            <div>No games available.</div>
                                        ) : (
                                            <select
                                                className="select2 select2-hidden-accessible"
                                                multiple
                                                data-placeholder="Select the Games"
                                                data-dropdown-css-class="select2-purple"
                                                style={{ width: '100%' }}
                                                tabIndex="-1"
                                                aria-hidden="true"
                                            >
                                                {gamesData.map(game => (
                                                    <option
                                                        key={game._id}
                                                        value={game._id}
                                                    >
                                                        {game.name} - {game.desc}
                                                    </option>
                                                ))}
                                            </select>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpdateAdmin}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6 d-flex align-items-center">
                                <h1 className="m-0 mr-3">{adminData.username == '' ? '' : adminData.username} | View Admin</h1>
                                <button className="btn btn-success" data-toggle="modal" data-target="#editAdminModal" onClick={() => {
                                    $('.select2').val(adminData.games);
                                    $('.select2').select2({
                                    }).on("change", function (e) {
                                        const selectedGameIds = $(this).val() || []; // Get the selected game IDs as an array

                                        // Update the games array in selectedAdmin based on the selected games
                                        setAdminData(prevState => ({
                                            ...prevState,
                                            games: selectedGameIds,
                                        }));
                                    });
                                }}>
                                    <i className="fas fa-pen"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>{subscriptionsData.length}</h3>
                                        <p>Keys</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-key" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        <h3>{
                                            subscriptionsData ?
                                                subscriptionsData.filter(sub => sub.activated === true).length
                                                : 0
                                        }</h3>
                                        <p>Keys Sold</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-key" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>{usersData.length}</h3>
                                        <p>Users</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-user-plus" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(adminData.credits)}
                                        </h3>
                                        <p>Credits</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-dollar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Main row */}
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-default">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="fas fa-user"></i> Users
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <table
                                            id="users_table"
                                            className="display"
                                            style={{ width: '100%' }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Username</th>
                                                    <th>IP</th>
                                                    <th>Creation Date</th>
                                                    <th>Last Login</th>
                                                    <th>Banned</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card card-default">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="fas fa-key"></i> Keys
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <table
                                            id="keys_table"
                                            className="display"
                                            style={{ width: '100%' }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Key</th>
                                                    <th>Game</th>
                                                    <th>Tier</th>
                                                    <th>Created At</th>
                                                    <th>Valid For</th>
                                                    <th>Activated By</th>
                                                    <th>Paused</th>
                                                    <th>Note</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="card card-default">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className="fas fa-key"></i> Keys Statistics
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <table
                                            id="keys_stats"
                                            className="display"
                                            style={{ width: '100%' }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Tier</th>
                                                    <th>Total</th>
                                                    <th>Days</th>
                                                    <th>Weeks</th>
                                                    <th>Months</th>
                                                    <th>Lifetime</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /.row (main row) */}

                    </div>{/* /.container-fluid */}
                </section>
            </div>
            <Footer />
        </div>
    );

}