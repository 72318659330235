import React, { useContext } from 'react'
import UserContext from '../user';

export default function Header() {
  const { signOut } = useContext(UserContext);

  const handleSignOut = (e) => {
    e.preventDefault();
    signOut();
  }

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-dark navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i><i className="fas fa-bars"></i></a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a className="nav-link" href="#" onClick={handleSignOut}>
              <i className="fas fa-sign-out"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

