import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext from '../../components/user';
import { useSelector } from 'react-redux';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false); // Add this line of code
  const { signIn } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const authentication = useSelector((state) => state.auth.authentication);

  useEffect(() => {
    
    if (authentication) {
      return navigate('/');
    }

    // Check if credentials are saved in localStorage
    const savedUsername = localStorage.getItem('username');
    const savedPassword = localStorage.getItem('password');

    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);


  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    const { from } = location.state || { from: { pathname: '/' } };
    setLoading(true);
    const res = await signIn(username, password);
    setLoading(false);
    if (res) {
      if (rememberMe) {
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
      } else {
        localStorage.removeItem('username');
        localStorage.removeItem('password');
      }
      navigate(from);
    }
  }

  return (
    <div className="login-page bg-body-secondary">
      <div className="login-box">
        <div className="login-logo">
          <img src="/assets/img/logoround.png" alt="Unkdev Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8', 'border-radius': 0 }} />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <form onSubmit={handleLoginSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="input-group mb-3">
                    <input type="text" className="form-control" name={'username_login'} id="username_login" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)}></input>
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-user"></span>
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input type="password" className="form-control" name={'password_login'} id="password_login" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={rememberMe}
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      id="flexCheckDefault"
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Remember Me
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <button type="submit" className="btn btn-block btn-primary" disabled={loading}>Sign In</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Login;